export class ApplicationRequest{
  name?:string;
  email?:string;
  phoneNumber?: string;
  address?:string;
  dob?: string;
  typeOfExperience?: string = 'N/A';
  cdl?: string = 'N/A';
  message?: string;
}
