import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { SecretsManagerServiceException as __BaseException } from "./SecretsManagerServiceException";
export const FilterNameStringType = {
  all: "all",
  description: "description",
  name: "name",
  owning_service: "owning-service",
  primary_region: "primary-region",
  tag_key: "tag-key",
  tag_value: "tag-value"
};
export class DecryptionFailure extends __BaseException {
  constructor(opts) {
    super({
      name: "DecryptionFailure",
      $fault: "client",
      ...opts
    });
    this.name = "DecryptionFailure";
    this.$fault = "client";
    Object.setPrototypeOf(this, DecryptionFailure.prototype);
    this.Message = opts.Message;
  }
}
export class InternalServiceError extends __BaseException {
  constructor(opts) {
    super({
      name: "InternalServiceError",
      $fault: "server",
      ...opts
    });
    this.name = "InternalServiceError";
    this.$fault = "server";
    Object.setPrototypeOf(this, InternalServiceError.prototype);
    this.Message = opts.Message;
  }
}
export class InvalidNextTokenException extends __BaseException {
  constructor(opts) {
    super({
      name: "InvalidNextTokenException",
      $fault: "client",
      ...opts
    });
    this.name = "InvalidNextTokenException";
    this.$fault = "client";
    Object.setPrototypeOf(this, InvalidNextTokenException.prototype);
    this.Message = opts.Message;
  }
}
export class InvalidParameterException extends __BaseException {
  constructor(opts) {
    super({
      name: "InvalidParameterException",
      $fault: "client",
      ...opts
    });
    this.name = "InvalidParameterException";
    this.$fault = "client";
    Object.setPrototypeOf(this, InvalidParameterException.prototype);
    this.Message = opts.Message;
  }
}
export class InvalidRequestException extends __BaseException {
  constructor(opts) {
    super({
      name: "InvalidRequestException",
      $fault: "client",
      ...opts
    });
    this.name = "InvalidRequestException";
    this.$fault = "client";
    Object.setPrototypeOf(this, InvalidRequestException.prototype);
    this.Message = opts.Message;
  }
}
export class ResourceNotFoundException extends __BaseException {
  constructor(opts) {
    super({
      name: "ResourceNotFoundException",
      $fault: "client",
      ...opts
    });
    this.name = "ResourceNotFoundException";
    this.$fault = "client";
    Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
    this.Message = opts.Message;
  }
}
export const StatusType = {
  Failed: "Failed",
  InProgress: "InProgress",
  InSync: "InSync"
};
export class EncryptionFailure extends __BaseException {
  constructor(opts) {
    super({
      name: "EncryptionFailure",
      $fault: "client",
      ...opts
    });
    this.name = "EncryptionFailure";
    this.$fault = "client";
    Object.setPrototypeOf(this, EncryptionFailure.prototype);
    this.Message = opts.Message;
  }
}
export class LimitExceededException extends __BaseException {
  constructor(opts) {
    super({
      name: "LimitExceededException",
      $fault: "client",
      ...opts
    });
    this.name = "LimitExceededException";
    this.$fault = "client";
    Object.setPrototypeOf(this, LimitExceededException.prototype);
    this.Message = opts.Message;
  }
}
export class MalformedPolicyDocumentException extends __BaseException {
  constructor(opts) {
    super({
      name: "MalformedPolicyDocumentException",
      $fault: "client",
      ...opts
    });
    this.name = "MalformedPolicyDocumentException";
    this.$fault = "client";
    Object.setPrototypeOf(this, MalformedPolicyDocumentException.prototype);
    this.Message = opts.Message;
  }
}
export class PreconditionNotMetException extends __BaseException {
  constructor(opts) {
    super({
      name: "PreconditionNotMetException",
      $fault: "client",
      ...opts
    });
    this.name = "PreconditionNotMetException";
    this.$fault = "client";
    Object.setPrototypeOf(this, PreconditionNotMetException.prototype);
    this.Message = opts.Message;
  }
}
export class ResourceExistsException extends __BaseException {
  constructor(opts) {
    super({
      name: "ResourceExistsException",
      $fault: "client",
      ...opts
    });
    this.name = "ResourceExistsException";
    this.$fault = "client";
    Object.setPrototypeOf(this, ResourceExistsException.prototype);
    this.Message = opts.Message;
  }
}
export const SortOrderType = {
  asc: "asc",
  desc: "desc"
};
export class PublicPolicyException extends __BaseException {
  constructor(opts) {
    super({
      name: "PublicPolicyException",
      $fault: "client",
      ...opts
    });
    this.name = "PublicPolicyException";
    this.$fault = "client";
    Object.setPrototypeOf(this, PublicPolicyException.prototype);
    this.Message = opts.Message;
  }
}
export const SecretValueEntryFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretBinary && {
    SecretBinary: SENSITIVE_STRING
  }),
  ...(obj.SecretString && {
    SecretString: SENSITIVE_STRING
  })
});
export const BatchGetSecretValueResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretValues && {
    SecretValues: obj.SecretValues.map(item => SecretValueEntryFilterSensitiveLog(item))
  })
});
export const CreateSecretRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretBinary && {
    SecretBinary: SENSITIVE_STRING
  }),
  ...(obj.SecretString && {
    SecretString: SENSITIVE_STRING
  })
});
export const GetRandomPasswordResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.RandomPassword && {
    RandomPassword: SENSITIVE_STRING
  })
});
export const GetSecretValueResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretBinary && {
    SecretBinary: SENSITIVE_STRING
  }),
  ...(obj.SecretString && {
    SecretString: SENSITIVE_STRING
  })
});
export const PutSecretValueRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretBinary && {
    SecretBinary: SENSITIVE_STRING
  }),
  ...(obj.SecretString && {
    SecretString: SENSITIVE_STRING
  })
});
export const UpdateSecretRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.SecretBinary && {
    SecretBinary: SENSITIVE_STRING
  }),
  ...(obj.SecretString && {
    SecretString: SENSITIVE_STRING
  })
});