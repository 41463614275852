import { Injectable } from '@angular/core';
import {GetSecretValueCommand, SecretsManagerClient} from "@aws-sdk/client-secrets-manager";
import {ApiKvPair} from "../models/api-kv-pair";
import {SendEmailCommand, SendEmailResponse, SESClient} from "@aws-sdk/client-ses";
import {ApplicationRequest} from "../models/application-request";
import {ServiceRequest} from "../models/service-request";

const REGION: string = 'us-east-1';
const CRED = {
  accessKeyId: 'AKIA6GBMH7EDLXHAJ5VA',
  secretAccessKey: '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'
}

const FROM_ADDRESS: string = 'itservice@qor-is.com';
const TO_ADDRESS: string = 'dispatch@qor-is.com';

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  private secretManager = new SecretsManagerClient({
    region: REGION,
    credentials: CRED
  });

  private ses: SESClient = new SESClient({
    region: REGION,
    credentials: CRED
  });

  constructor() { }

  public async getDeveloperKey():Promise<ApiKvPair>{
    try {
      const response = await this.secretManager.send(
        new GetSecretValueCommand({
          SecretId: "qor/web/li/api",
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        })
      );

      return new ApiKvPair(response.Name ?? '', response.SecretString ?? '');
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }
  }

  public async sendMail(request: ApplicationRequest | ServiceRequest): Promise<SendEmailResponse>{
    try {
      let cmd: SendEmailCommand;
      if(request instanceof ApplicationRequest){
        cmd = this.getApplicationEmail(request);
      }else{
        cmd = this.getServiceRequestEmail(request);
      }

      return await this.ses.send(cmd);
    } catch (e) {
      console.error("Failed to send email.", e);
      return Promise.resolve({
         MessageId: JSON.stringify(e)
      } as SendEmailResponse)
    }
  }

  private getApplicationEmail(request: ApplicationRequest): SendEmailCommand{
    return new SendEmailCommand({
      Destination: {
        /* required */
        CcAddresses: [
          /* more items */
        ],
        ToAddresses: [
          'dispatch@qor-is.com',
          'itservice@qor-is.com'
          /* more To-email addresses */
        ],
      },
      Message: {
        /* required */
        Body: {
          /* required */
          Html: {
            Charset: "UTF-8",
            Data: `
<style>
label{
  font-family: 'Arial',sans-serif;
}
table{
\tborder: 1px solid black;
\tfont-size: 24pt;
  font-family: 'Arial',sans-serif;
  min-width: 500px;
}
tr.header{
  background-color: #373863;
  color: white;
  text-align: center;
  font-size: 30pt;
}
th{
  padding: 5px;
}
tr.field{}
td{
  border: 1px solid lightgray;
  text-wrap: normal;
  padding: 5px;
}
td.field{
  font-weight: bold;
  width: 350px;
}
td.value{}
</style>

<table>
<thead>
  <tr class="header">
    <th colspan="2">
      Job Application
    </th>
  </tr>
</thead>
<tbody>
  <tr>
    <td class="field">Name: </td>
    <td class="value"> ${request.name ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Email: </td>
    <td class="value"> ${request.email ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Phone Number: </td>
    <td class="value">${request.phoneNumber ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Address: </td>
    <td class="value">${request.address ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Date Of Birth: </td>
    <td class="value"> ${request.dob ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Type Of Experience: </td>
    <td class="value">${request.typeOfExperience ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">CDL: </td>
    <td class="value">${request.cdl ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Additional Information: </td>
    <td class="value">${request.message ?? ''}</td>
  </tr>
</tbody>
</table>
            `,
          },
          Text: {
            Charset: "UTF-8",
            Data: "",
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: "Job Application Received",
        },
      },
      Source: FROM_ADDRESS,
      ReplyToAddresses: [
        /* more items */
        request.email ?? '[EMAIL NOT PROVIDED]'
      ],
    })
  }

  private getServiceRequestEmail(request: ServiceRequest): SendEmailCommand{
    return new SendEmailCommand({
      Destination: {
        /* required */
        CcAddresses: [
          /* more items */
        ],
        ToAddresses: [
          'dispatch@qor-is.com',
          'itservice@qor-is.com'
          /* more To-email addresses */
        ],
      },
      Message: {
        /* required */
        Body: {
          /* required */
          Html: {
            Charset: "UTF-8",
            Data: `
<style>
label{
  font-family: 'Arial',sans-serif;
}
table{
\tborder: 1px solid black;
\tfont-size: 24pt;
  font-family: 'Arial',sans-serif;
  min-width: 500px;
}
tr.header{
  background-color: #373863;
  color: white;
  text-align: center;
  font-size: 30pt;
}
th{
  padding: 5px;
}
tr.field{}
td{
  border: 1px solid lightgray;
  text-wrap: normal;
  padding: 5px;
}
td.field{
  font-weight: bold;
  width: 350px;
}
td.value{}
</style>

<table>
<thead>
  <tr class="header">
    <th colspan="2">
      Service Request
    </th>
  </tr>
</thead>
<tbody>
  <tr>
    <td class="field">Name: </td>
    <td class="value"> ${request.name ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Email: </td>
    <td class="value"> ${request.email ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Phone Number: </td>
    <td class="value">${request.phoneNumber ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Delivery Location: </td>
    <td class="value">${request.deliveryLocation ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Date Needed: </td>
    <td class="value"> ${request.dateNeeded ?? 'N/A'}</td>
  </tr>
  <tr>
    <td class="field">Service(s) Needed: </td>
    <td class="value">${request.requestedServices.map(x => x.name).join(', ')}</td>
  </tr>
  <tr>
    <td class="field">Additional Information: </td>
    <td class="value">${request.message ?? ''}</td>
  </tr>
</tbody>
</table>
            `,
          },
          Text: {
            Charset: "UTF-8",
            Data: "",
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: "Service Request Received",
        },
      },
      Source: FROM_ADDRESS,
      ReplyToAddresses: [
        /* more items */
        request.email ?? '[EMAIL NOT PROVIDED]'
      ],
    })
  }
}
