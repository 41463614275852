import { Component } from '@angular/core';
import {MapComponent} from "../common/components/map/map.component";
import {RouterLink} from "@angular/router";
import {ContactFormComponent} from "../common/components/contact-form/contact-form.component";

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    MapComponent,
    RouterLink,
    ContactFormComponent
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {

}
