<app-loader></app-loader>

<app-nav-header></app-nav-header>

<router-outlet></router-outlet>

<!-- Main Footer Start -->
<app-footer></app-footer>
<!-- Main Footer End -->

<!-- Back To Top Start -->
<a id="mkdf-back-to-top" class="off" (click)="scroller.setOffset([0,0])"><i class="icofont-rounded-up"></i></a>
<!-- Back To Top End -->
