import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivationStart, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {NavHeaderComponent} from "./common/components/nav-header/nav-header.component";
import {LoaderComponent} from "./common/components/loader/loader.component";
import {FooterComponent} from "./common/components/footer/footer.component";
import * as WOW from 'wow.js/dist/wow.min.js';
import {ViewportScroller} from "@angular/common";
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";

@Component({
  selector: 'app-root',
  standalone: true,
    imports: [RouterOutlet, RouterLink, RouterLinkActive, NgbTooltip, NavHeaderComponent, LoaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'QOR Companies';

  constructor(private router: Router,
              protected scroller: ViewportScroller) {
    this.router.events.subscribe(event => this.onNavigate(event));
  }

  private onNavigate(event: any):void{
    if(event instanceof ActivationStart){
      new WOW().init();
      setTimeout(() => {
        this.headerMinHeight();
      });
    }
  }

  private headerMinHeight(): void{
    var headerHeight = $('header').height();
    // @ts-ignore
    $('header').css('minHeight', headerHeight);
  }

  ngOnInit() {
    // $('#pageloader').delay(1200).fadeOut('slow');
    // $(".loader-item").delay(700).fadeOut();
    //
    // new WOW().init();
    //
    // // @ts-ignore
    // $('select').niceSelect();
    //
    // if($("#yearText").length > 0){
    //   // @ts-ignore
    //   document.getElementById("yearText").innerHTML = new Date().getFullYear();
    // }
    //
    // function headerMinHeight(){
    //   let headerHeight = $('header').height();
    //   // @ts-ignore
    //   $('header').css('minHeight', headerHeight);
    // }
    //
    // headerMinHeight();
    //
    // // Dropdown Menu For Mobile
    // $('.dropdown-menu a.dropdown-submenu-toggle').on('click', function (e) {
    //   if (!$(this).next().hasClass('show')) {
    //     $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    //   }
    //   var $subMenu = $(this).next(".dropdown-menu");
    //   $subMenu.toggleClass('show');
    //
    //   $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    //     $('.dropdown-submenu .show').removeClass("show");
    //   });
    //
    //   return false;
    // });
    //
    // $('[data-toggle="tooltip"]').tooltip();
  }
}
