import { Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {EquipmentComponent} from "./equipment/equipment.component";
import {IndustrialComponent} from "./industrial/industrial.component";
import {AboutUsComponent} from "./about-us/about-us.component";
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {GalleryComponent} from "./gallery/gallery.component";
import {CareersComponent} from "./careers/careers.component";
import {PrivacyComponent} from "./privacy/privacy.component";

export const routes: Routes = [
  {path: '', redirectTo:'home', pathMatch: "full"},
  {path: 'home', component: HomeComponent, data:{header: 'home'}},
  {path: 'industrial-services', component: IndustrialComponent, data:{header: 'services'}},
  {path: 'equipment-and-rentals', component: EquipmentComponent, data:{header: 'equipment'}},
  {path: 'about-us', component: AboutUsComponent, data:{header: 'other'}},
  {path: 'contact-us', component: ContactUsComponent, data:{header: 'other'}},
  {path: 'gallery', component: GalleryComponent, data: {header: 'other'}},
  {path: 'careers', component: CareersComponent, data: {header: 'other'}},
  {path: 'privacy-policy', component: PrivacyComponent, data: {header: 'other'}}
];
