<!-- Google Map Start -->
<section class="map-bg-with-contact ">
  <div class="container pos-rel">
    <div class="contact-details-on-map">
      <div class="row">
        <div class="col-md-6">
          <h4>Houston</h4>
          <p><i class="icofont-phone"></i> (281) 542 - 4800</p>
          <div class="text-nowrap"><i class="icofont-email"></i> <a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a></div>
        </div>
      </div>
    </div>
  </div>
  <div id="map-holder" class="pos-rel">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3460.5546776324313!2d-95.16228208780649!3d29.848273374918772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640a0d5545bffff%3A0xc1501a0e8b97a775!2sQor%20Industrial%20Service!5e0!3m2!1sen!2sus!4v1710104011027!5m2!1sen!2sus"></iframe>
  </div>
</section>
<!-- Google Map Start -->
