<!-- Page Breadcrumbs Start -->
<div class="slider bg-navy-blue bg-fixed pos-rel breadcrumbs-page breadcrumbs-page-2">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#" [routerLink]="['../home']"><i class="icofont-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Careers</li>
      </ol>
    </nav>

    <h1>Careers</h1>
    <div class="breadcrumbs-description">
      Want to be part of the QOR family? Fill out the form below and our hiring staff will get in touch with you.
    </div>
  </div>
</div>
<!-- Page Breadcrumbs End -->

<!-- Main Body Content Start -->
<main id="body-content">

  <!-- Contact Details Start -->
  <section class="wide-tb-80" style="background-color: white;">
    <!--    <div class="container">-->
    <!--      <div class="contact-map-bg">-->
    <!--        <img src="assets/images/map-bg.png" alt="">-->
    <!--      </div>-->
    <!--      <div class="row justify-content-between">-->
    <!--        <div class="col-md-6 col-sm-12 col-lg-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">-->
    <!--          <div class="contact-detail-shadow">-->
    <!--            <h4>Germany</h4>-->
    <!--            <div class="d-flex align-items-start items">-->
    <!--              <i class="icofont-google-map"></i>-->
    <!--              <span>-->
    <!--              8410 Miller Road 3-->
    <!--              Houston, TX 77049-->
    <!--            </span>-->
    <!--            </div>-->
    <!--            <div class="d-flex align-items-start items">-->
    <!--              <i class="icofont-phone"></i> <span>(281) 452 - 4800</span>-->
    <!--            </div>-->
    <!--            <div class="text-nowrap d-flex align-items-start items">-->
    <!--              <i class="icofont-email"></i> <a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </section>
  <!-- Contact Details End -->

  <!-- Contact Us From -->
  <section class="wide-tb-100" style="background-color: white;">
    <div class="container">
      <!--      <div class="contact-map-bg">-->
      <!--        <img src="assets/images/map-bg.png" alt="">-->
      <!--      </div>-->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12 mx-auto wow fadeInUp" data-wow-duration="0"
             data-wow-delay="0s">
          <div class="free-quote-form contact-page">
            <!-- Heading Main -->
            <h1 class="heading-main mb-4">
              Apply Here
            </h1>
            <!-- Heading Main -->

            <div class="container">
              <div class="contact-map-bg option">
                <img src="assets/images/map-bg.png" alt="">
              </div>

              <div class="row">
                <div class="col-md-8 col-sm-12">
                  <h2 class="h2-md mb-4 fw-7 txt-blue">Please fill out our form and we will contact you!</h2>
                  <div class="">

                    <div class="free-quote-form contact-page-option wow fadeInLeft" data-wow-duration="0"
                         data-wow-delay="0s">
                      <form id="applyOption" #applyForm="ngForm"
                            novalidate="novalidate" class="rounded-field">
                        <div class="g-4 row mb-4">
                          <div class="col-md mb-0">
                            <input #name="ngModel" type="text" name="name" class="form-control" placeholder="Name (First Last)"
                              [(ngModel)]="request.name" [required]="true" [minlength]="3" [max]="75">
                            <div [hidden]="name.valid || name.pristine"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Name is required and must be at least 3 characters.
                            </div>
                          </div>
                          <div class="col-md mb-0">
                            <input #email="ngModel" type="text" name="email" class="form-control" placeholder="Email"
                              [(ngModel)]="request.email" [required]="true" [minlength]="5">
                            <div [hidden]="(email.valid || email.pristine) && (email.pristine || request.email?.includes('@'))"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Invalid Email
                            </div>
                          </div>
                          <div class="col-md mb-0">
                            <input #phone="ngModel" type="text" name="phone" class="form-control" placeholder="Phone Number (xxx-xxx-xxxx)"
                              [(ngModel)]="request.phoneNumber" [required]="true" [minlength]="10">
                            <div [hidden]="phone.valid || phone.pristine"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Invalid
                            </div>
                          </div>
                        </div>
                        <div class="g-4 row mb-4">
                          <div class="col-md mb-0">
                            <input #address="ngModel" type="text" name="address" class="form-control" placeholder="Address (Street, City, State Zip)"
                              [(ngModel)]="request.address">
                          </div>
                          <div class="col-md mb-0">
                            <input #birthday="ngModel" type="text" name="birthday" class="form-control rounded-field"
                                   placeholder="Birthday (mm/dd/yyyy)" [(ngModel)]="request.dob" [required]="true" [minlength]="8">
                            <div [hidden]="birthday.valid || birthday.pristine"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Invalid
                            </div>
                          </div>
                        </div>
                        <div class="g-4 row mb-4">
                          <div class="col-md mb-0">
                            <select #toe="ngModel" name="toe" title="Please choose your type of experience" required="true" class="form-control form-select wide"
                                    aria-required="true" aria-invalid="false"
                                    [(ngModel)]="request.typeOfExperience">
                              <option value="N/A">Type Of Experience</option>
                              <option value="Roll-Off">Roll-off</option>
                              <option value="Vacuum Services">Vacuum Services</option>
                              <option value="Air Mover">Air Mover</option>
                              <option value="Other">Other</option>
                            </select>
                            <div [hidden]="(toe.valid || toe.pristine)"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Required
                            </div>
                          </div>
                          <div class="col-md mb-0">
                            <select #cdl="ngModel" id="cdl" name="cdl" title="Please choose a license type" [required]="true" class="form-control form-select wide"
                                    aria-required="true" aria-invalid="false"
                                    [(ngModel)]="request.cdl">
                              <option *ngFor="let item of cdlTypes" [value]="item.value">{{ item.key }}</option>
                            </select>
                            <div [hidden]="(cdl.valid || cdl.pristine)"
                                 class="text-danger ms-1">
                              <i class="fa fa-exclamation-triangle"></i>
                              Required
                            </div>
                          </div>
                        </div>
                        <div class="g-4 row mb-4">
                          <div class="col-md mb-0">
                              <textarea #message="ngModel" name="message" rows="7" placeholder="Feel free to elaborate more on your experience or provide additional information on your answers above"
                              class="form-control"
                              [(ngModel)]="request.message"></textarea>
                          </div>
                        </div>
                        <div class="form-row text-center">

                          <button class="form-btn mx-auto btn-theme bg-orange" (click)="onApplicationSubmit($event)"
                                  *ngIf="!emailSent"
                            [disabled]="applyForm.invalid" [ngClass]="{'disabled': applyForm.invalid}">
                            Submit Now
                            <i class="icofont-rounded-right"></i>
                          </button>

                          <div class="alert alert-success" *ngIf="emailSent">
                            Your application has been sent!
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>

                <div class="col-md-4">
                  <h2 class="h2-md mb-4 fw-7 txt-blue">Our Address</h2>
                  <!-- Contact Detail Left -->
                  <div class="contact-detail-shadow no-shadow mb-5 wow fadeInRight" data-wow-duration="0"
                       data-wow-delay="0s">
                    <div class="d-flex align-items-start items">
                      <i class="icofont-google-map"></i>
                      <span>
                        8410 Miller Road 3
                        Houston, TX 77049
                      </span>
                    </div>
                    <div class="d-flex align-items-start items">
                      <i class="icofont-phone"></i> <span>(281) 452 - 4800</span>
                    </div>
                    <div class="text-nowrap d-flex align-items-start items">
                      <i class="icofont-email"></i> <a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a>
                    </div>
                    <div class="text-nowrap d-flex align-items-start items">
                      <i class="icofont-clock-time me-3"></i>
                      <div class="media-body">
                        <div><strong>Mon - Fri</strong></div>
                        <div>7:00 AM - 7:00 PM CST</div>

                        <div><strong>Saturday</strong></div>
                        <div>7:00 AM - 12:00 PM CST</div>

                        <div><strong>Sunday</strong></div>
                        <div>Closed</div>
                      </div>
                    </div>
                  </div>
                  <!-- Contact Detail Left -->

                  <div class="mt-2">
                    <div class="d-flex items">
                      <i class="icofont-exclamation-circle mt-1 me-1"></i>
                      <span>Potential hires will be subject to background check, drug test, and review of driving record.</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Us From -->

  <!-- Google Map Start -->
  <section class="map-bg">
    <app-map></app-map>
  </section>
  <!-- Google Map Start -->
</main>

<!--<script src="assets/js/site-custom.js"></script>-->

