<!-- Page Breadcrumbs Start -->
<div class="slider bg-navy-blue bg-fixed pos-rel breadcrumbs-page breadcrumbs-page-3">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']"><i class="icofont-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Industrial Services</li>
      </ol>
    </nav>

    <h1>QOR Industrial Services</h1>
    <div class="breadcrumbs-description">
      Complete industrial services for upstream, midstream, and downstream markets.
    </div>
  </div>
</div>
<!-- Page Breadcrumbs End -->

<!-- Main Body Content Start -->
<main id="body-content">
  <!-- What We Offer Start -->
  <section class="wide-tb-80">
    <div class="container pos-rel">
      <div class="row align-items-start">

        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-md-12 mb-5">
              <div class="text-left">
                <img src="assets/images/blog_single.jpg" alt="" class="rounded mb-4">
                <h4 class="h4-md mb-3 txt-blue fw-7">
                  End-To-End Industrial Services
                </h4>
                <p>
                  QOR Industrial Services offers complete industrial services for upstream, midstream, and downstream
                  markets. Our state-of-the-art automated 3D equipment, combined with our experienced and skilled
                  personnel, ensures that we deliver the highest level of cleaning services.
                </p>
                <p>
                  Our hydro blasting
                  capabilities range from 10,000 PSI to over 40,000 PSI, and apply to multiple sectors of the industry.
                  Our complete waste management services include waste sampling, waste profile setup, manifest
                  generation, and transportation to disposal, and provide a reliable and efficient solution for your
                  waste management needs.
                </p>

              </div>


            </div>

            <div class="col-sm-12 col-md-4 col-lg-4">
              <h3 class="h3-md fw-7 mb-4 txt-blue">Services Include</h3>
              <ul class="list-unstyled icons-listing theme-orange mb-0">
                <li>
                  <i class="icofont-double-right"></i>
                  Hydro Blasting
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Chemical Tank Cleaning
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Hydro and Pneumatic Excavation
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Separator and Exchanger Cleaning
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                   Sewer Services
                </li>


                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Vacuum Services
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Turnaround Assistance
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Crude Oil Reclamation
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Soil Remediation
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Barge Cleaning
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Site-based Spill Response
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  On-Site Waste Sampling & Profile Setup
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Manifest Generation
                </li>
                <li class="hide-lg">
                  <i class="icofont-double-right"></i>
                  Waste Coordination & Management
                </li>
              </ul>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 hide-sm">
              <h3 class="h3-md fw-7 mb-4 txt-blue">&nbsp;</h3>
              <ul class="list-unstyled icons-listing theme-orange mb-0">
                <li>
                  <i class="icofont-double-right"></i>
                  Vacuum Services
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Turnaround Assistance
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Crude Oil Reclamation
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Soil Remediation
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Barge Cleaning
                </li>
              </ul>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 hide-sm">
              <h3 class="h3-md fw-7 mb-4 txt-blue">&nbsp;</h3>
              <ul class="list-unstyled icons-listing theme-orange mb-0">
                <li>
                  <i class="icofont-double-right"></i>
                  Site-based Spill Response
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  On-Site Waste Sampling & Profile Setup
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Manifest Generation
                </li>
                <li>
                  <i class="icofont-double-right"></i>
                  Waste Coordination & Management
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="col-md-12 col-lg-4">
          <!-- Add Some Left Space -->
          <aside class="sidebar-spacer row">

            <!-- Sidebar Primary Start -->
            <div class="sidebar-primary col-lg-12 col-md-6">

              <!-- Recent Post Widget Start -->
              <div class="widget-wrap">
                <h3 class="h3-md fw-7 mb-4">Recent Posts</h3>
                <div class="blog-list-footer">
                  <ul class="list-unstyled">
                    <li>
                      <div class="media">
                        <div #container class="media-body post-text" style="min-height: 700px;">
                          <!-- LINKEDIN FEED -->
                          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7173750765201682433" height="700" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
              <!-- Recent Post Widget End -->
            </div>
            <!-- Sidebar Primary End -->

            <div class="widget-wrap">
                <img src="assets/images/qor/combo-unit.jpg" alt="Combo Unit" class="rounded mb-4 img-resp" >
            </div>

          </aside>
          <!-- Add Some Left Space -->
        </div>

      </div>

    </div>
  </section>
  <!-- What We Offer End -->

  <!-- Callout Start -->
  <section class="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
    <div class="bg-overlay blue opacity-60"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-12 mb-0">
          <h4 class="h4-xl">Interested in working with QOR Companies?</h4>
        </div>
        <div class="col">

        </div>
        <div class="col-sm-auto">
          <a href="#" class="btn btn-theme bg-white bordered" [routerLink]="['../contact-us']">
            Get In Touch
            <i class="icofont-rounded-right"></i></a>
        </div>
      </div>
    </div>
  </section>
  <!-- Callout End -->
</main>



