<!-- Page Breadcrumbs Start -->
<div class="slider bg-navy-blue bg-fixed pos-rel breadcrumbs-page breadcrumbs-page-3">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../home']"><i class="icofont-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Equipment & Rentals</li>
      </ol>
    </nav>

    <h1>QOR Equipment & Rentals</h1>
    <div class="breadcrumbs-description">
      Waste equipment and rentals delivered to your door.
    </div>
  </div>
</div>
<!-- Page Breadcrumbs End -->

<!-- Main Body Content Start -->
<main id="body-content">
  <!-- What We Offer Start -->
  <section class="wide-tb-80">
    <div class="container pos-rel">
      <div class="row align-items-start">

        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-md-12 mb-5">
              <div class="text-left">
                <img src="assets/images/qor/40yd-box-inv.jpg" alt="" class="rounded mb-4">
                <h4 class="h4-md mb-3 txt-blue fw-7">
                  From Delivery To Pickup
                </h4>
                <p>
                  QOR Equipment and Rentals is the solution to all your waste rental needs. Our rental fleet, which is
                  comprised of company-owned boxes and tanks, containment berms, specialty containers and tankers, and
                  an assortment of hoses and fittings is diverse enough to handle waste projects in various environments
                  of all sizes. This includes chemical plants, oil refineries, tank terminals, construction sites,
                  marine shipyards, and more. Our transportation capabilities cover both hazardous and non-hazardous
                  solid and liquid special waste, as well as non-hazardous construction and demolition waste.
                  No job is too big or too small for QOR. We pride ourselves on completing every project safely, efficiently, and
                  in a timely manner.
                </p>

              </div>


            </div>

            <div class="col-sm-12 col-md-12 col-lg-12">
              <h3 class="h3-md fw-7 mb-4 txt-blue">Rentals & Equipment We Have To Offer</h3>
              <div class="theme-collapse arrow-right-border">
                <!-- Toggle Links 1 -->
                <div class="toggle2">
                  Rental Container Fleet
                </div>
                <!-- Toggle Links 1 -->

                <!-- Toggle Content 1 -->
                <div class="collapse show">
                  <div class="content">
                    <ul class="list-unstyled icons-listing theme-orange mb-0">
                      <div class="row resp-div">
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          25 Yard Vacuum Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          20, 25 Yard Roll Tarp Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          25 Yard Vacuum De-watering Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          25 Yard Roll Tarp De-watering Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          25 Yard Metal Lid Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          20, 25, 30, 40 Yard Open Top Boxes
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          500bbl Frac Tanks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          200bbl Mini Frac Tanks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Poly Tanks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Specialty Roll-Off Containers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          All Size Containment Berms
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Various Tank Fittings
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Stainless Steel Tanker Trailers
                        </li>
                      </div>

                    </ul>
                  </div>
                </div>
                <!-- Toggle Content 1 -->

                <!-- Toggle Links 2 -->
                <div class="toggle2">
                  Waste Transportation
                </div>
                <!-- Toggle Links 2 -->

                <!-- Toggle Content 2 -->
                <div class="collapse show">
                  <div class="content">
                    <ul class="list-unstyled icons-listing theme-orange mb-0">
                      <div class="row resp-div">
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          3-Axle Roll-Off Trucks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          5-Axle Roll-Off Trucks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Sleeper Trucks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Single Hauler Roll-Off Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Double Hauler Roll-Off Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Winch Trucks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          70bbl Vacuum Trucks
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          130bbl Vacuum Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          32 Yard End Dump Soil Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          45-60 Yard End Dump Construction Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Stainless Steel Tank Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          52' Box Vans
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Curtain Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Flat Bed Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Drum Trailers
                        </li>
                        <li class="col-lg-6 col-md-6 col-sm-12">
                          <i class="icofont-double-right"></i>
                          Hydro-excavation Trucks
                        </li>
                      </div>

                    </ul>
                  </div>
                </div>
                <!-- Toggle Content 2 -->

              </div>
            </div>
          </div>
        </div>


        <div class="col-md-12 col-lg-4">
          <!-- Add Some Left Space -->
          <aside class="sidebar-spacer row">

            <!-- Sidebar Primary Start -->
            <div class="sidebar-primary col-lg-12 col-md-6">

              <!-- Recent Post Widget Start -->
              <div class="widget-wrap">
                <h3 class="h3-md fw-7 mb-4">Recent Posts</h3>
                <div class="blog-list-footer">
                  <ul class="list-unstyled">
                    <li>
                      <div class="media">
                        <div #container class="media-body post-text" style="min-height: 700px;">
<!--                          <div class="juicer-feed" data-feed-id="qor-industrial-services"-->
<!--                               data-truncate="690"-->
<!--                               data-interval="10000" data-per="15"></div>-->
                          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7173330205434994688" height="641" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
              <!-- Recent Post Widget End -->
            </div>
            <!-- Sidebar Primary End -->

            <div class="widget-wrap">
              <img src="assets/images/qor/3-mini-fracs.jpg" alt="Combo Unit" class="rounded mb-4 img-resp" >
            </div>

            <div class="widget-wrap">
              <img src="assets/images/qor/vac-box-inventory.jpg" alt="Combo Unit" class="rounded mb-4 img-resp" >
            </div>

            <div class="widget-wrap">
              <img src="assets/images/qor/vac-box-inventory2.jpg" alt="Combo Unit" class="rounded mb-4 img-resp" >
            </div>
          </aside>
          <!-- Add Some Left Space -->
        </div>

      </div>

    </div>
  </section>
  <!-- What We Offer End -->

  <!-- Callout Start -->
  <section class="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
    <div class="bg-overlay blue opacity-60"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-12 mb-0">
          <h4 class="h4-xl">Interested in working with QOR Companies?</h4>
        </div>
        <div class="col">

        </div>
        <div class="col-sm-auto">
          <a href="#" class="btn btn-theme bg-white bordered" [routerLink]="['../contact-us']">
            Get In Touch
            <i class="icofont-rounded-right"></i></a>
        </div>
      </div>
    </div>
  </section>
  <!-- Callout End -->
</main>

