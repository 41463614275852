import {AfterViewInit, Component} from '@angular/core';
import {GalleryImage} from "../common/models/gallery-image";
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MapComponent} from "../common/components/map/map.component";
import {FooterComponent} from "../common/components/footer/footer.component";
import {AboutUsComponent} from "../about-us/about-us.component";
import {NavHeaderComponent} from "../common/components/nav-header/nav-header.component";

@Component({
  selector: 'app-home',
  standalone: true,
    imports: [
        NgForOf,
        RouterLink,
        MapComponent,
        FooterComponent,
        AboutUsComponent,
        NavHeaderComponent
    ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements AfterViewInit{
  constructor() {
  }

  ngAfterViewInit(): void {
    this.initLayoutFive();
  }

  private initLayoutOne():void{
    $(document).ready(function () {
      // @ts-ignore
      if ($("#rev_slider_1078_1").revolution == undefined) {
        console.error("#rev_slider_1078_1");
      } else {
        // @ts-ignore
        revapi1078 = $("#rev_slider_1078_1").show().revolution({
          sliderType: "standard",
          jsFileLocation: "revolution/js/",
          sliderLayout: "fullscreen",
          dottedOverlay: "none",
          delay: 9000,
          navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation: "off",
            mouseScrollReverse: "default",
            onHoverStop: "off",
            touch: {
              touchenabled: "on",
              swipe_threshold: 75,
              swipe_min_touches: 1,
              swipe_direction: "horizontal",
              drag_block_vertical: false
            }
            ,
            arrows: {
              style: "metis",
              enable: true,
              hide_onmobile: true,
              hide_under: 600,
              hide_onleave: true,
              hide_delay: 200,
              hide_delay_mobile: 1200,
              //tmp:'<div class="tp-title-wrap">    <div class="tp-arr-imgholder"></div> </div>',
              left: {
                h_align: "left",
                v_align: "center",
                h_offset: 30,
                v_offset: 0
              },
              right: {
                h_align: "right",
                v_align: "center",
                h_offset: 30,
                v_offset: 0
              }
            }
            ,
            bullets: {
              style: 'hades',
              tmp: '<span class="tp-bullet-image"></span>',
              enable: false,
              hide_onmobile: true,
              hide_under: 600,
              //style:"metis",
              hide_onleave: true,
              hide_delay: 200,
              hide_delay_mobile: 1200,
              direction: "horizontal",
              h_align: "center",
              v_align: "bottom",
              h_offset: 0,
              v_offset: 30,
              space: 5,
              //tmp:'<span class="tp-bullet-img-wrap">  <span class="tp-bullet-image"></span></span><span class="tp-bullet-title">{{title}}</span>'
            }
          },
          viewPort: {
            enable: true,
            outof: "pause",
            visible_area: "80%",
            presize: false
          },
          responsiveLevels: [1240, 1024, 778, 480],
          visibilityLevels: [1240, 1024, 778, 480],
          gridwidth: [1240, 1024, 778, 480],
          gridheight: [600, 600, 500, 400],
          lazyType: "none",
          parallax: {
            type: "mouse",
            origo: "slidercenter",
            speed: 2000,
            levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50, 47, 48, 49, 50, 51, 55]
          },
          shadow: 0,
          spinner: 'spinner2',
          stopLoop: "off",
          stopAfterLoops: -1,
          stopAtSlide: -1,
          shuffle: "off",
          autoHeight: "off",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false,
          }
        });
      }
    }); /*ready*/
  }

  private initLayoutFive():void{
    $(document).ready(function () {
      "use strict";

      // DATA BACKGROUND IMAGE
      var pageSection = $(".bg-image");
      pageSection.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css("background-image", "url(" + $(this).data("background") + ")");
        }
      });
    });

    // SLIDER
    // @ts-ignore
    var menu = [];
    jQuery('.swiper-slide').each(function (index) {
      menu.push(jQuery(this).find('.slide-inner').attr("data-text"));
    });
    var interleaveOffset = 0.5;

    var swiperOptions = {
      loop: true,
      speed: 2500,
      parallax: true,
      autoplay: {
        delay: 10500,
        disableOnInteraction: false,
      },
      grabCursor: true,
      watchSlidesProgress: true,
      pagination: {
        el: '.swiper-custom-pagination',
        clickable: true,
        // @ts-ignore
        renderBullet: function (index, className) {
          // @ts-ignore
          return '<span class="' + className + '">' + (menu[index]) + '</span>';
        },
      },
      on: {
        progress: function () {
          var swiper = this;
          // @ts-ignore
          for (var i = 0; i < swiper.slides.length; i++) {
            // @ts-ignore
            var slideProgress = swiper.slides[i].progress;
            // @ts-ignore
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            // @ts-ignore
            swiper.slides[i].querySelector(".slide-inner").style.transform =
              "translate3d(" + innerTranslate + "px, 0, 0)";
          }
        },
        touchStart: function () {
          var swiper = this;
          // @ts-ignore
          for (var i = 0; i < swiper.slides.length; i++) {
            // @ts-ignore
            swiper.slides[i].style.transition = "";
          }
        },// @ts-ignore
        setTransition: function (speed) {
          var swiper = this;
          // @ts-ignore
          for (var i = 0; i < swiper.slides.length; i++) {
            // @ts-ignore
            swiper.slides[i].style.transition = speed + "ms";
            // @ts-ignore
            swiper.slides[i].querySelector(".slide-inner").style.transition =
              speed + "ms";
          }
        }
      }
    };
    // @ts-ignore
    var swiper = new Swiper(".swiper-container", swiperOptions);
  }
}
