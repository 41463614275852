import {AfterViewInit, Component, Input, Optional, ViewChild} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {NgbActiveModal, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaV3Module, ReCaptchaV3Service} from "ng-recaptcha";
import {FormsModule, NgForm} from "@angular/forms";
import {RobotService} from "../../services/robot.service";
import {tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ServiceRequest} from "../../models/service-request";
import {AwsService} from "../../services/aws.service";
import {ApplicationRequest} from "../../models/application-request";

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    FormsModule,
    NgForOf,
    NgClass,
    NgbTooltip
  ],
  providers:[
    HttpClient
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements AfterViewInit{
  //region Declarations
  @ViewChild('requestForm') form!: NgForm;

  @Input() isModal: boolean = false;

  protected request = new ServiceRequest();

  protected emailSent: boolean = false;

  protected get formInvalid():boolean{
    return this.form?.invalid ?? false;
  }

  //endregion

  constructor(@Optional() private modalInstance: NgbActiveModal,
              private robotService: RobotService,
              private aws: AwsService) {
  }

  ngAfterViewInit() {
    // @ts-ignore
    // $('select').niceSelect();
  }

  onCancel() {
    this.modalInstance?.close(false);
  }

  onAddService($event: MouseEvent) {
    $event.preventDefault();
    $event.cancelBubble = true;
    $event.stopPropagation();

    this.request.addNewService();
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    $event.cancelBubble = true;
    $event.stopPropagation();


    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6Lf56pcpAAAAAGfPHcJlC40EnV8Nh7MgT7sS6RQO', {action: 'CONTACT_SUBMIT'});

      let subscription = this.robotService.checkToken(token).pipe(tap(x => {
        if(x.riskAnalysis.score > 0.3){

          if(this.isRequestValid()){
            this.aws.sendMail(this.request).then(result => {

              if((result.MessageId?.length ?? 0) > 0){
                this.emailSent = true;
                this.form.resetForm(new ServiceRequest());
                setTimeout(() => {
                  this.modalInstance?.close(true);
                }, 3000);
              }
            });
          }

          subscription.unsubscribe();
        }
      })).subscribe()
    });
  }

  protected isRequestValid():boolean{
    if((this.request.name?.trim().length ?? 0) < 3) return false;
    if((this.request.email?.trim().length ?? 0) < 3 || !this.request.email?.includes('@')) return false;
    if((this.request.phoneNumber?.trim().length ?? 0) < 10) return false;
    return true;
  }

  hasServiceSelected(service: string, index: number): boolean {
    return (!(this.request.requestedServices[index].name === service)
        && this.request.requestedServices.some(x => x.name === service));
  }

  removeServiceItem(index: number) {
    this.request.requestedServices.splice(index, 1);
  }
}
