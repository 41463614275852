import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ContactFormComponent} from "../common/components/contact-form/contact-form.component";
import {MapComponent} from "../common/components/map/map.component";
import {RouterLink} from "@angular/router";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {tap} from "rxjs";
import {RobotService} from "../common/services/robot.service";
import {ApplicationRequest} from "../common/models/application-request";
import {FormsModule, NgForm} from "@angular/forms";
import {AwsService} from "../common/services/aws.service";
import {KvPair} from "../common/models/kv-pair";
import {NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-careers',
  standalone: true,
  imports: [
    ContactFormComponent,
    MapComponent,
    RouterLink,
    NgIf,
    FormsModule,
    NgForOf,
    NgClass
  ],
  templateUrl: './careers.component.html',
  styleUrl: './careers.component.scss'
})
export class CareersComponent implements AfterViewInit{
  //region Declarations

  @ViewChild('applyForm') form!: NgForm;

  protected request: ApplicationRequest = new ApplicationRequest();

  protected emailSent: boolean = false;

  protected cdlTypes: Array<KvPair> = [
    {key: 'CDL License?', value: 'N/A'},
    {key: 'None', value: 'None'},
    {key: 'Class A', value: 'Class A'},
    {key: 'Class B', value: 'Class B'},
  ];

  //endregion
  constructor(private robotService: RobotService,
              private aws: AwsService,
              private toast: ToastrService) {
  }

  ngAfterViewInit() {

  }

  onApplicationSubmit($event: MouseEvent) {
    $event.preventDefault();
    $event.cancelBubble = true;
    $event.stopPropagation();

    console.log(this.request);

    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6Lf56pcpAAAAAGfPHcJlC40EnV8Nh7MgT7sS6RQO', {action: 'APPLICATION_SUBMIT'});

      let subscription = this.robotService.checkToken(token).pipe(tap(x => {
        if(x.riskAnalysis.score > 0.3){

          if(this.isRequestValid()){
            this.aws.sendMail(this.request).then(result => {

              if((result.MessageId?.length ?? 0) > 0){
                this.emailSent = true;
                this.form.resetForm(new ApplicationRequest());
              }
            });
          }

          subscription.unsubscribe();
        }
      })).subscribe()
    });
  }

  protected isRequestValid():boolean{
    if((this.request.name?.trim().length ?? 0) < 3) return false;
    if((this.request.email?.trim().length ?? 0) < 3 || !this.request.email?.includes('@')) return false;
    if((this.request.phoneNumber?.trim().length ?? 0) < 10) return false;
    if((this.request.address?.trim().length ?? 0) === 0) return false;
    if((this.request.dob?.trim().length ?? 0) < 10) return false;
    if((this.request.cdl?.trim().length ?? 0) < 0) return false;
    if((this.request.typeOfExperience?.trim().length ?? 0) < 0) return false;
    return true;
  }
}
