import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RobotService {

  constructor(private httpClient: HttpClient) {
  }

  checkToken(token: string): Observable<any> {
    return this.httpClient.post('https://recaptchaenterprise.googleapis.com/v1/projects/qor-companies-1710358030494/assessments?key=AIzaSyA5tj3PxMm_CX7mbdM_-EVvNDbCd6roGUc', {
      event: {
        token: token,
        expectedAction: 'CONTACT_SUBMIT',
        siteKey: '6Lf56pcpAAAAAGfPHcJlC40EnV8Nh7MgT7sS6RQO'
      }
    });
  }
}
