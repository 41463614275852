<!-- Our Gallery Start -->
<section class="bg-white wide-tb-100">
  <div class="container pos-rel">
    <div class="row">
      <!-- Heading Main -->
      <div class="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
        <h1 class="heading-main">
          <span>our Gallery</span>
          Photo Showcase
        </h1>
      </div>
      <!-- Heading Main -->
    </div>

    <div id="js-styl2-mosaic" class="cbp">
      <div class="cbp-item design" *ngFor="let i of gallery" style="width: auto !important; max-width: 550px;">
        <div class="gallery-link">
          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>
        </div>
        <a [href]="i.path" class="cbp-caption cbp-lightbox" [attr.data-title]="i.title">
          <div class="cbp-caption-defaultWrap">
            <img [src]="i.path" [alt]="i.title" style="height:auto; width: auto; max-width: 550px; object-fit: contain" >
          </div>
          <div class="cbp-caption-activeWrap">
            <div class="cbp-l-caption-alignCenter">
              <div class="cbp-l-caption-body">
                <i class="icofont-search icofont-2x txt-white"></i>
              </div>
            </div>
          </div>
        </a>
      </div>

      <!--      <div class="cbp-item design">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/qor/dh-vac-boxes.jpg" class="cbp-caption cbp-lightbox" data-title="Double hauler with vac boxes">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/qor/dh-vac-boxes.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item illustration">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/portfolio/fullwidth/img-3.jpg" class="cbp-caption cbp-lightbox" data-title="Lorem ipsum-->
      <!--">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/portfolio/fullwidth/img-3.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item photography">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/portfolio/fullwidth/img-7.jpg" class="cbp-caption cbp-lightbox" data-title="Lorem ipsum-->
      <!--">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/portfolio/fullwidth/img-7.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item identity">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/portfolio/fullwidth/img-8.jpg" class="cbp-caption cbp-lightbox" data-title="Lorem ipsum-->
      <!--">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/portfolio/fullwidth/img-8.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item business">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/qor/40yd-box-inv.jpg" class="cbp-caption cbp-lightbox" data-title="Lorem ipsum-->
      <!--">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/qor/40yd-box-inv.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item photography">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/portfolio/fullwidth/img-10.jpg" class="cbp-caption cbp-lightbox"-->
      <!--           data-title="Lorem ipsum">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/portfolio/fullwidth/img-10.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="cbp-item business">-->
      <!--        <div class="gallery-link">-->
      <!--          <a href="project-single.html" class="txt-white"><i class="icofont-external-link"></i></a>-->
      <!--        </div>-->
      <!--        <a href="assets/images/portfolio/fullwidth/img-11.jpg" class="cbp-caption cbp-lightbox"-->
      <!--           data-title="Lorem ipsum">-->
      <!--          <div class="cbp-caption-defaultWrap">-->
      <!--            <img src="assets/images/portfolio/fullwidth/img-11.jpg" alt="">-->
      <!--          </div>-->
      <!--          <div class="cbp-caption-activeWrap">-->
      <!--            <div class="cbp-l-caption-alignCenter">-->
      <!--              <div class="cbp-l-caption-body">-->
      <!--                <i class="icofont-search icofont-2x txt-white"></i>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->
    </div>

  </div>
</section>
<!-- Our Gallery End -->
