
<!-- Page Breadcrumbs Start -->
<div class="slider bg-navy-blue bg-fixed pos-rel breadcrumbs-page" *ngIf="!compressed">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#" [routerLink]="['../home']"><i class="icofont-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">About Us</li>
      </ol>
    </nav>

    <h1>About Us</h1>
    <div class="breadcrumbs-description">
      Learn about our QOR services and find out more about how we work.
    </div>
  </div>
</div>
<!-- Page Breadcrumbs End -->

<!-- What Makes Us Special Start -->
<section class="wide-tb-80">
  <div class="container pos-rel">
    <div class="row align-items-center">

      <div class="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
        <h2 class="mb-4 fw-7 txt-blue d-flex align-items-center">
          <div class="me-2" style="font-size: 2.5rem;">About</div>
          <img class="ml-1 mt-2" src="assets/images/qor/qor.png"
               style="width: 200px; height: auto; object-fit: contain"/>
        </h2>

        <p>
          QOR Companies are a group of premier industrial service companies that provide comprehensive solutions for
          handling, storing, and transporting industrial process waste for ongoing facility needs as well as small and
          large-scale industrial projects.
        </p>

        <p>
          Our team is comprised of environmental and technical experts who understand DOT compliance requirements and
          state waste regulations, as well as industry standards for handling hazardous and non-hazardous waste both
          locally and across the contiguous United States. We combine industry best practices with the highest level of
          safety standards to meet customer objectives while managing project costs and productivity.
        </p>

        <p>
          Our management team has extensive experience in the industrial waste business across the Gulf Coast
          and can manage your waste project from the early planning phase through to completion.
        </p>
      </div>


      <div class="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
        <img src="assets/images/map-bg-orange.jpg" alt="">
      </div>

    </div>

  </div>
</section>
<!-- About QOR End -->

<!-- What Makes Us Special Start -->
<section class="bg-light-gray wide-tb-100 pb-5 why-choose">
  <div class="container pos-rel">

    <div class="row">
      <!-- Heading Main -->
      <div class="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
        <h1 class="heading-main">
          <span>QOR Principles</span>
          What Makes Us Special
        </h1>
      </div>
      <!-- Heading Main -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              <i class="icofont-id"></i>
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">New Rental & Transportation Fleet</h4>
              <p>Built to the highest industry standards and sufficient for both large and small quantity waste
                generators.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.2s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              <i class="icofont-garbage"></i>
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">End-To-End Turnkey Waste Handling</h4>
              <p>Solid/Liquid Transportation, Tank Blasting/Cleaning, Box & Tank Rentals, and more.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.4s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              <i class="icofont-laboratory"></i>
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">In-House Services</h4>
              <p>
                Waste sampling, profiling, and manifest generation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->
    </div>

    <div class="row mt-5">
      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              <i class="icofont-id"></i>
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">Trusted Partner</h4>
              <p>Proven with over 90 years experience in the waste industry.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.2s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              <i class="icofont-live-support"></i>
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">Customer Support</h4>
              <p>Available 24 hours per day, 7 days a week.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.4s">
        <div class="icon-box-2">
          <div class="media">
            <div class="service-icon">
              A+
            </div>
            <div class="service-inner-content media-body">
              <h4 class="h4-md">ISNetworld® Rating</h4>
              <p>
                Issued to contractor companies based on the thoroughness and
                quality of its industrial compliance and safety standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Icon Box -->
    </div>

    <div class="row">
      <div class="col-12 col-lg-12 col-md-12 col-sm-12 wwow fadeInUp mt-5">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <span class="badge bp">EPA: TXR000085000</span>
          <span class="badge bp">USDOT: 3239147</span>
          <span class="badge bp">WHP: 7833</span>
          <span class="badge bp">OPERATING AUTH: 1017048</span>
          <span class="badge bp">APPORTION: 171309</span>
          <span class="badge bp">NASIC: 484220</span>
          <span class="badge bp">SAIC: 8999</span>
          <span class="badge bp">HAZMAT REGISTRATION: 060122550330EF</span>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- What Makes Us Special End -->


<!-- Turnkey Waste Management Start -->
<section class="bg-light-gray wide-tb-100 pb-5 why-choose" *ngIf="!compressed">
  <div class="container pos-rel">

    <div class="row">
      <!-- Heading Main -->
      <div class="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
        <h1 class="heading-main">
          <span>QOR Services</span>
          Turnkey Waste Management
        </h1>
      </div>
      <!-- Heading Main -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.2s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
          <div class="readonly">
            <!--            <i class="icofont-rounded-down "></i> -->
            Industrial Tank Cleaning
          </div>
          <!-- Toggle Links 1 -->

          <!-- Toggle Content 1 -->
          <div class="collapse">
            <div class="content">

            </div>
          </div>
          <!-- Toggle Content 1 -->

          <!-- Toggle Links 2 -->
          <div class="readonly">
            Waste Sampling
          </div>
          <!-- Toggle Links 2 -->

          <!-- Toggle Content 2 -->
          <div class="collapse">
            <div class="content">

            </div>
          </div>
          <!-- Toggle Content 2 -->

          <!-- Toggle Links 3 -->
          <div class="readonly">
            Waste Profiling
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Content 3 -->
          <div class="collapse">
            <div class="content">
              <!--              <ul>-->
              <!--                <li>Bulk liquid and solid waste</li>-->
              <!--                <li>Chemical tote and drum waste</li>-->
              <!--              </ul>-->
            </div>
          </div>
          <!-- Toggle Content 3 -->

        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
          <div class="readonly">
            <!--            <i class="icofont-rounded-down "></i> -->
            Waste Analysis
          </div>
          <!-- Toggle Links 1 -->



          <!-- Toggle Links 2 -->
          <div class="readonly">
            Waste Manifesting
          </div>
          <!-- Toggle Links 2 -->

          <!-- Toggle Content 2 -->
          <div class="collapse">
            <div class="content">

            </div>
          </div>
          <!-- Toggle Content 2 -->

          <!-- Toggle Links 3 -->
          <div class="toggle">
            <i class="icofont-rounded-down "></i>
            Waste Transportation
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Content 3 -->
          <div class="collapse">
            <div class="content">
              <ul>
                <li>Bulk liquid and solid waste</li>
                <li>Chemical tote and drum waste</li>
              </ul>
            </div>
          </div>
          <!-- Toggle Content 3 -->

        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.4s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
<!--          <app-toggle>-->
<!--            Waste Disposal-->
<!--          </app-toggle>-->
          <div class="toggle">
            <i class="icofont-rounded-down "></i>
            Waste Disposal
          </div>
          <!-- Toggle Links 1 -->

          <!-- Toggle Content 1 -->
          <div class="collapse">
            <div class="content">
              <ul>
                <li>In-Plant Construction & Demolition Waste</li>
                <li>Non-Hazardous Special Waste</li>
                <li>Hazardous Special Waste</li>
                <li>Oil/Gas E&P</li>
                <li>Railroad Commission Waste</li>
                <li>NORM Waste</li>
                <li>Construction & Demolition Waste</li>
              </ul>
            </div>
          </div>
          <!-- Toggle Content 1 -->

          <!-- Toggle Links 2 -->
          <div class="readonly">
            Waste Clean-out
          </div>
          <!-- Toggle Links 2 -->

          <!-- Toggle Content 2 -->
          <div class="collapse">
            <div class="content">

            </div>
          </div>
          <!-- Toggle Content 2 -->

          <!-- Toggle Links 3 -->
          <div class="readonly">
            On-site Services
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Content 3 -->
          <div class="collapse">
            <div class="content">

            </div>
          </div>
          <!-- Toggle Content 3 -->

        </div>
      </div>
      <!-- Icon Box -->
    </div>


  </div>
</section>
<!-- Turnkey Waste Management End -->

<!-- Potential Customers Start -->
<section class="bg-light-gray wide-tb-100 pb-5 why-choose" *ngIf="!compressed">
  <div class="container pos-rel">

    <div class="row">
      <!-- Heading Main -->
      <div class="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
        <h1 class="heading-main">
          <span>QOR Audience</span>
          Potential Customers
        </h1>
      </div>
      <!-- Heading Main -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.2s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
          <div class="readonly">
            Oil Refineries
          </div>
          <!-- Toggle Links 1 -->


          <!-- Toggle Links 2 -->
          <div class="readonly">
            Chemical Plants
          </div>
          <!-- Toggle Links 2 -->


          <!-- Toggle Links 3 -->
          <div class="readonly">
            Bulk Tank Terminals
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Links 4 -->
          <div class="readonly">
            Marine Shipyards
          </div>
          <!-- Toggle Links 4 -->

        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
          <div class="readonly">
            Pipeline Substations
          </div>
          <!-- Toggle Links 1 -->


          <!-- Toggle Links 2 -->
          <div class="readonly">
            Municipal Utility Districts
          </div>
          <!-- Toggle Links 2 -->


          <!-- Toggle Links 3 -->
          <div class="readonly">
            Manufacturing Facilities
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Links 4 -->
          <div class="readonly">
            Waste Water Treatment Plants
          </div>
          <!-- Toggle Links 4 -->

        </div>
      </div>
      <!-- Icon Box -->

      <!-- Icon Box 2 -->
      <div class="col-12 col-lg-4 wow fadeInUp mt-2" data-wow-duration="0" data-wow-delay="0.4s">
        <div class="theme-collapse capsules">
          <!-- Toggle Links 1 -->
          <div #toggle class="readonly" >
            Upstream / Midstream / Downstream Industrial Markets
          </div>
          <!-- Toggle Links 1 -->


          <!-- Toggle Links 2 -->
          <div class="readonly">
            Pipeline Construction & Maintenance Contractors
          </div>
          <!-- Toggle Links 2 -->


          <!-- Toggle Links 3 -->
          <div class="readonly">
            Plant & Refinery Construction/Maintenance Contractors
          </div>
          <!-- Toggle Links 3 -->

          <!-- Toggle Links 4 -->
          <div class="readonly">
            Industrial Soft Craft Contractors
          </div>
          <!-- Toggle Links 4 -->

        </div>
      </div>
      <!-- Icon Box -->
    </div>


  </div>
</section>
<!-- Potential Customers End -->

<!-- Callout Start -->
<section class="wide-tb-80 bg-navy-blue callout-style-1 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s"
         *ngIf="!compressed">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-12 mb-0">
        <h4 class="h4-xl">Interested in working with QOR Companies?</h4>
      </div>
      <div class="col">
        <!--        <div class="center-text">-->
        <!--          We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized-->
        <!--          approach-->
        <!--        </div>-->
      </div>
      <div class="col-sm-auto">
        <a href="#" class="btn-theme bg-white light" [routerLink]="['../contact-us']">Get In Touch
          <i class="icofont-rounded-right"></i></a>
      </div>
    </div>
  </div>
</section>
<!-- Callout End -->


<!-- Theme Custom FIle -->
<!--<script src="assets/js/site-custom.js"></script>-->
