<!-- Main Footer Start -->
<footer class="wide-tb-70 bg-light-theme pb-0">
  <div class="container">
    <div class="row">

      <!-- Column First -->
      <div class="col-lg-3 col-md-6">
        <div class="logo-footer h-100 w-100 d-flex justify-content-center align-items-center">
          <img src="assets/images/qor/qor-logo-blue.png" alt="QOR Logo Blue">
        </div>
      </div>
      <!-- Column First -->

      <!-- Column Second -->
      <div class="col-lg-3 col-md-6">
        <h3 class="footer-heading">Quick Navigation</h3>
        <div class="footer-widget-menu">
          <ul class="list-unstyled">
            <li><a href="#" [routerLink]="['../home']"><i class="icofont-simple-right"></i> <span>Home</span></a></li>
            <li><a href="#" [routerLink]="['../about-us']"><i class="icofont-simple-right"></i> <span>About us</span></a></li>
            <li><a href="#" [routerLink]="['../industrial-services']"><i class="icofont-simple-right"></i> <span>QOR Industrial Services</span></a></li>
            <li><a href="#" [routerLink]="['../equipment-and-rentals']"><i class="icofont-simple-right"></i> <span>QOR Equipment and Rentals</span></a></li>
            <li><a href="#" [routerLink]="['../careers']"><i class="icofont-simple-right"></i> <span>Careers</span></a></li>
            <li><a href="#" [routerLink]="['../contact-us']"><i class="icofont-simple-right"></i> <span>Contact Us</span></a></li>
          </ul>
        </div>
      </div>
      <!-- Column Second -->

      <!-- Column Third -->
      <div class="col-lg-3 col-md-6">
        <h3 class="footer-heading">Get In Touch</h3>
        <div class="footer-widget-contact">
          <div class="media mb-3">
            <i class="icofont-google-map me-3"></i>
            <div class="media-body">8410 Miller Road 3
              Houston, TX 77049
            </div>
          </div>
          <div class="media mb-3">
            <i class="icofont-smart-phone me-3"></i>
            <div class="media-body mt-1">
              <div>(281) 452 - 4800</div>
            </div>
          </div>
          <div class="media mb-3">
            <i class="icofont-ui-email me-3"></i>
            <div class="media-body">
              <div><a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a></div>
            </div>
          </div>
          <div class="media mb-3">
            <i class="icofont-clock-time me-3"></i>
            <div class="media-body">
              <div><strong>Mon - Fri</strong></div>
              <div>7:00 AM - 7:00 PM CST</div>

              <div><strong>Saturday</strong></div>
              <div>7:00 AM - 12:00 PM CST</div>

              <div><strong>Sunday</strong></div>
              <div>Closed</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column Third -->

      <!-- Column Fourth -->
      <div class="col-lg-3 col-md-6">
        <h3 class="footer-heading">We're Social On</h3>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/qor-industrial-services/"><i class="icofont-linkedin icofont-2x"></i></a>
        </div>
      </div>
      <!-- Column Fourth -->



    </div>
  </div>

  <div class="copyright-wrap bg-navy-blue wide-tb-30">
    <div class="container">
      <div class="row text-md-start text-center">
        <div class="col-sm-12 col-md-6 copyright-links">
          <a href="#" [routerLink]="['../privacy-policy']">Privacy Policy</a>
        </div>
        <div class="col-sm-12 col-md-6 text-md-end text-center">
          QOR Companies © <span id="yearText"></span> All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Main Footer End -->
