<!-- Page Breadcrumbs Start -->
<div class="slider bg-navy-blue bg-fixed pos-rel breadcrumbs-page breadcrumbs-page-2">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#" [routerLink]="['../home']"><i class="icofont-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
      </ol>
    </nav>

    <h1>Contact Us</h1>
    <div class="breadcrumbs-description">
      Get in touch with a QOR representative and find out how we can help you today!
    </div>
  </div>
</div>
<!-- Page Breadcrumbs End -->

<!-- Main Body Content Start -->
<main id="body-content">

  <!-- Contact Details Start -->
  <section class="wide-tb-80" style="background-color: white;">
<!--    <div class="container">-->
<!--      <div class="contact-map-bg">-->
<!--        <img src="assets/images/map-bg.png" alt="">-->
<!--      </div>-->
<!--      <div class="row justify-content-between">-->
<!--        <div class="col-md-6 col-sm-12 col-lg-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">-->
<!--          <div class="contact-detail-shadow">-->
<!--            <h4>Germany</h4>-->
<!--            <div class="d-flex align-items-start items">-->
<!--              <i class="icofont-google-map"></i>-->
<!--              <span>-->
<!--              8410 Miller Road 3-->
<!--              Houston, TX 77049-->
<!--            </span>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-start items">-->
<!--              <i class="icofont-phone"></i> <span>(281) 452 - 4800</span>-->
<!--            </div>-->
<!--            <div class="text-nowrap d-flex align-items-start items">-->
<!--              <i class="icofont-email"></i> <a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
  <!-- Contact Details End -->

  <!-- Contact Us From -->
  <section class="wide-tb-100" style="background-color: white;">
    <div class="container">
<!--      <div class="contact-map-bg">-->
<!--        <img src="assets/images/map-bg.png" alt="">-->
<!--      </div>-->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12 mx-auto wow fadeInUp" data-wow-duration="0"
             data-wow-delay="0s">
          <div class="free-quote-form contact-page">
            <!-- Heading Main -->
            <h1 class="heading-main mb-4">
              Get in touch
            </h1>
            <!-- Heading Main -->

            <app-contact-form></app-contact-form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Us From -->

  <!-- Google Map Start -->
  <section class="map-bg">
    <app-map></app-map>
  </section>
  <!-- Google Map Start -->
</main>

