<ng-container *ngTemplateOutlet="isModal ? modal : contactForm"></ng-container>

<ng-template #modal>
  <div class="modal-header bg-navy-blue text-white">
    <h2>
      <i class="fa fa-code-pull-request me-1"></i>
      Request QOR Services
    </h2>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="contactForm"></ng-container>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center h-100 w-100">
      <button class="btn btn-default" (click)="onCancel()">Cancel</button>
      <button class="form-btn btn-theme bg-orange" (click)="onSubmit($event)"
              [disabled]="formInvalid || emailSent" [ngClass]="{'disabled': formInvalid || emailSent}">
        Submit Now
        <i class="fa fa-send ms-1"></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #contactForm>
  <div class="container">
    <div class="contact-map-bg option">
      <img src="assets/images/map-bg.png" alt="">
    </div>

    <div class="row">
      <div class="col-md-8 col-sm-12">
        <h2 class="h2-md mb-4 fw-7 txt-blue">Please fill out our form and we will contact you!</h2>
        <div class="">

          <div class="free-quote-form contact-page-option wow fadeInLeft" data-wow-duration="0"
               data-wow-delay="0s">
            <form id="request-form" #requestForm="ngForm"
                  novalidate="novalidate" class="rounded-field">
              <div class="g-4 row mb-4">
                <div class="col-md mb-0">
                  <input #name="ngModel" type="text" name="name" class="form-control" placeholder="Your Name / Company Name"
                         [(ngModel)]="request.name" [required]="true" [minlength]="3" [max]="75">
                  <div [hidden]="name.valid || name.pristine"
                       class="text-danger ms-1">
                    <i class="fa fa-exclamation-triangle"></i>
                    Name is required and must be at least 3 characters.
                  </div>
                </div>
                <div class="col-md mb-0">
                  <input #email="ngModel" type="text" name="email" class="form-control" placeholder="Email"
                         [(ngModel)]="request.email" [required]="true" [minlength]="5">
                  <div [hidden]="(email.valid || email.pristine) && (email.pristine || request.email?.includes('@'))"
                       class="text-danger ms-1">
                    <i class="fa fa-exclamation-triangle"></i>
                    Invalid Email
                  </div>
                </div>
                <div class="col-md mb-0">
                  <input #phone="ngModel" type="text" name="phone" class="form-control" placeholder="Phone Number (xxx-xxx-xxxx)"
                         [(ngModel)]="request.phoneNumber" [required]="true" [minlength]="10">
                  <div [hidden]="phone.valid || phone.pristine"
                       class="text-danger ms-1">
                    <i class="fa fa-exclamation-triangle"></i>
                    Invalid
                  </div>
                </div>
              </div>
              <div class="g-4 row mb-4">
                <div class="col-md mb-0">
                  <input type="text" name="delivery-location" class="form-control" placeholder="Delivery Location (Optional)"
                    [(ngModel)]="request.deliveryLocation">
                </div>
                <div class="col-md mb-0">
                  <input #dInput type="text" name="date-needed" class="form-control rounded-field" placeholder="Date Needed (Optional)"
                         (focus)="dInput.type = 'date'" (mouseover)="dInput.type = 'date'"
                  [(ngModel)]="request.dateNeeded">
                </div>
              </div>
              @for (service of request.requestedServices;track service.$id;let idx = $index) {
                <div class="g-4 row mb-4">
                  <div class="col-lg mb-0">
                    <div class="d-flex">
                      <select #services="ngModel" [attr.name]="'service-'+idx" [ngModelOptions]="{name:'service-'+idx}"
                              title="Please select services" class="form-control form-select wide" aria-required="true"
                              aria-invalid="false" [(ngModel)]="request.requestedServices[idx].name">
                        <option value="N/A">Service Needed (Optional)</option>
                        <option value="Roll-off Boxes" *ngIf="!hasServiceSelected('Roll-off Boxes', idx)">Roll-off Boxes</option>
                        <option value="Vacuum Services" *ngIf="!hasServiceSelected('Vacuum Services', idx)">Vacuum Services</option>
                        <option value="Tank Cleaning" *ngIf="!hasServiceSelected('Tank Cleaning', idx)">Tank Cleaning</option>
                        <option value="Frac Tanks" *ngIf="!hasServiceSelected('Frac Tanks', idx)">Frac Tanks</option>
                        <option value="Waste Disposal" *ngIf="!hasServiceSelected('Waste Disposal', idx)">Waste Disposal</option>
                        <option value="Waste Transportation" *ngIf="!hasServiceSelected('Waste Transportation', idx)">Waste Transportation</option>
                        <option value="End Dump Hauling" *ngIf="!hasServiceSelected('End Dump Hauling', idx)">End Dump Hauling</option>
                      </select>
                      <i class="fad fa-trash ms-1 mt-2" style="font-size: 1.5rem; cursor: pointer;" [ngbTooltip]="'Remove Service Item'"
                         container="body" *ngIf="idx > 0" (click)="removeServiceItem(idx)"></i>
                    </div>
                  </div>
                </div>
              }
              <div class="g-4 row mb-4">
                <div class="col-lg mb-0" style="margin: 10px;">
                  <button class="btn-sm btn-theme bg-navy-blue" (click)="onAddService($event)" [disabled]="request.requestedServices.length >= 7"
                    [ngClass]="{'disabled': request.requestedServices.length >= 7}">
                    Add Service
                  </button>
                </div>
              </div>
              <div class="g-4 row mb-4">
                <div class="col-md mb-0">
                    <textarea name="cment" rows="7" placeholder="Message"
                              class="form-control" [(ngModel)]="request.message"></textarea>
                </div>
              </div>
              <div class="form-row text-center" *ngIf="!isModal">

                <button class="form-btn mx-auto btn-theme bg-orange" (click)="onSubmit($event)"
                  [disabled]="requestForm.invalid" [ngClass]="{'disabled': requestForm.invalid}"
                  *ngIf="!emailSent">
                  Submit Now
                  <i class="icofont-rounded-right"></i>
                </button>

                <div class="alert alert-success" *ngIf="emailSent">
                  Your request has been sent!
                  <span class="ms-1" *ngIf="isModal">(This window will close automatically)</span>
                </div>

              </div>
              <div class="form-row text-center" *ngIf="isModal">
                <div class="alert alert-success" *ngIf="emailSent">
                  Your request has been sent!
                  <span class="ms-1" *ngIf="isModal">(This window will close automatically)</span>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>

      <div class="col-md-4">
        <h2 class="h2-md mb-4 fw-7 txt-blue">Our Address</h2>
        <!-- Contact Detail Left -->
        <div class="contact-detail-shadow no-shadow mb-5 wow fadeInRight" data-wow-duration="0"
             data-wow-delay="0s">
          <div class="d-flex align-items-start items">
            <i class="icofont-google-map"></i>
            <span>
              8410 Miller Road 3
              Houston, TX 77049
            </span>
          </div>
          <div class="d-flex align-items-start items">
            <i class="icofont-phone"></i> <span>(281) 452 - 4800</span>
          </div>
          <div class="text-nowrap d-flex align-items-start items">
            <i class="icofont-email"></i> <a href="mailto:dispatch@qor-is.com">dispatch&#64;qor-is.com</a>
          </div>
          <div class="text-nowrap d-flex align-items-start items">
            <i class="icofont-clock-time me-3"></i>
            <div class="media-body">
              <div><strong>Mon - Fri</strong></div>
              <div>7:00 AM - 7:00 PM CST</div>

              <div><strong>Saturday</strong></div>
              <div>7:00 AM - 12:00 PM CST</div>

              <div><strong>Sunday</strong></div>
              <div>Closed</div>
            </div>
          </div>
        </div>
        <!-- Contact Detail Left -->

      </div>
    </div>
  </div>
</ng-template>


