import {AfterViewInit, Component, inject, Input} from '@angular/core';
import {NgbModal, NgbOffcanvas, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {ActivationStart, NavigationStart, Router, RouterLink} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";
import {ContactFormComponent} from "../contact-form/contact-form.component";

export type HeaderType = 'home' | 'other' | 'services' | 'equipment';

@Component({
  selector: 'app-nav-header',
  standalone: true,
  imports: [
    NgbTooltip,
    RouterLink,
    NgClass,
    NgIf
  ],
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.scss'
})
export class NavHeaderComponent implements AfterViewInit{
  //region Declarations

  @Input() header: HeaderType = 'home'

  protected get isHomeHeader():boolean{
    return (this.header === 'home');
  }

  protected get qorLogo():string{
    if(this.isHomeHeader){
      return "assets/images/qor/qor-logo-white.png";
    }else if(this.header === 'services'){
      return "assets/images/qor/qor-industrial-services.png"
    }else if(this.header === 'equipment'){
      return "assets/images/qor/qor-equipment-rentals.png"
    }
    return "assets/images/qor/qor-logo-blue.png";
  }

  //endregion

  constructor(private router: Router,
              private modalService: NgbModal) {
    this.router.events.subscribe(event => this.onNavigate(event));
  }

  ngAfterViewInit() {
    this.headerMinHeight();
  }

  private headerMinHeight(){
    let headerHeight = $('header').height();

    // @ts-ignore
    $('header').css('minHeight', headerHeight);
  }

  private onNavigate(event: any):void{
    if(event instanceof ActivationStart){
      this.header = (event.snapshot.data['header'] ?? 'other');
    }
  }

  openContactForm() {
    let modalInstance = this.modalService.open(ContactFormComponent, {
      animation: true,
      size: 'xl',
      keyboard: true,
      backdrop: 'static',
      centered: true,
      scrollable: true
    });

    modalInstance.componentInstance.isModal = true;

    modalInstance.result.then(result =>{

    });
  }
}
