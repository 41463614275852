import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-industrial',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './industrial.component.html',
  styleUrl: './industrial.component.scss'
})
export class IndustrialComponent implements AfterViewInit{

  @ViewChild('container') container!: ElementRef;

  ngAfterViewInit() {

  }

}
