import {Guid} from "../objects/guid";

export class ServiceRequest{
  name?: string;
  email?: string;
  phoneNumber?: string;
  deliveryLocation?: string;
  dateNeeded?: Date;
  requestedServices: Array<ServiceItem> = [new ServiceItem()];
  message?: string;

  public get resolvedServices(): Array<ServiceItem>{
    let validServices = this.requestedServices.filter(x => x.name !== 'N/A') ?? [];
    if(validServices.length === 0){
      validServices.push(new ServiceItem());
    }
    return validServices;
  }

  public addNewService():void{
    if(this.requestedServices.length < 7){
      this.requestedServices.push(new ServiceItem());
    }
  }
}

export class ServiceItem{
  $id: string = Guid.create().toString();
  name: string = 'N/A';

  constructor(name?: string) {
    this.name = name ?? 'N/A';
  }
}
