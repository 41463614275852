import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-equipment',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './equipment.component.html',
  styleUrl: './equipment.component.scss'
})
export class EquipmentComponent implements AfterViewInit, OnDestroy{

  @ViewChild('container') container!: ElementRef;


  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }
}
