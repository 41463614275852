<header [ngClass]="{'header-sixth': isHomeHeader}">
  <div class="top-bar d-flex align-items-center text-md-left" *ngIf="!isHomeHeader">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <i class="icofont-google-map"></i>
          8410 Miller Road 3
          Houston, TX 77049
        </div>
        <div class="col-md-auto">

          <span class="me-3"><i class="icofont-ui-touch-phone"></i> (281) 452-4800</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Navigation Start -->
  <nav class="header-fullpage navbar navbar-expand-lg">
    <div class="container">
      <a class="navbar-brand" href="index.html">
        <img [src]="qorLogo" alt="QOR logo blue">
      </a>

      <!-- Topbar Request Quote Start -->
      <div class="d-flex order-lg-last align-items-center request-btn">
        <a class="btn-theme icon-left no-shadow align-self-center" role="button"
           [ngClass]="{'bg-orange': isHomeHeader, 'bg-navy-blue': !isHomeHeader}"
           (click)="openContactForm()">
          <i class="icofont-list"></i>
          <span class="d-none d-sm-inline-block"> Request Services</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- Topbar Request Quote End -->

      <div class="navbar-collapse offcanvas offcanvas-start offcanvas-collapse" id="navbarCollapse" [ngClass]="{'nav-light': isHomeHeader}">
        <div class="offcanvas-header">
          <a class="navbar-brand" href="index.html"><img src="assets/images/qor/qor-logo-blue.png" alt=""></a>
          <button class="navbar-toggler x collapsed" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarCollapse"
                  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="icofont-close-line"></i>
          </button>
        </div>
        <div class="offcanvas-body w-100">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <li class="nav-item">
              <a class="nav-link" href="#" [routerLink]="['../home']" data-bs-dismiss="offcanvas">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"  [routerLink]="['../about-us']" data-bs-dismiss="offcanvas">
                About Us
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">Services
                <i class="icofont-rounded-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" [routerLink]="['../industrial-services']"  data-bs-dismiss="offcanvas">QOR Industrial Services</a></li>
                <li><a class="dropdown-item" [routerLink]="['../equipment-and-rentals']" data-bs-dismiss="offcanvas">QOR Equipment and Rentals</a></li>
                <li><a class="dropdown-item text-muted" disabled style="cursor: default; color: lightgray !important;" data-bs-dismiss="offcanvas">QOR Clean Out (Coming Soon)</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                Contact Us
                <i class="icofont-rounded-down"></i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" [routerLink]="['../careers']" data-bs-dismiss="offcanvas">Careers</a></li>
                <li><a class="dropdown-item" href="#" [routerLink]="['../contact-us']" data-bs-dismiss="offcanvas">Contact Us</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"  [routerLink]="['../gallery']" data-bs-dismiss="offcanvas">
                Gallery
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <!-- Main Navigation End -->
</header>
