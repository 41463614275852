<!-- Page loader Start -->
<div id="pageloader">
  <div class="loader-item">
    <div class="loader">
      <div class="spin"></div>
      <div class="bounce"></div>
    </div>
  </div>
</div>
<!-- Page loader End -->
