import {AfterViewInit, Component, Input, OnInit, Renderer2} from '@angular/core';
import {NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {NavHeaderComponent} from "../common/components/nav-header/nav-header.component";
import {ToggleComponent} from "../common/components/toggle/toggle.component";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NavHeaderComponent,
    ToggleComponent
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements AfterViewInit{
  //region Declarations
    @Input() compressed: boolean = false;
  //endregion

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    $('.toggle').on("click", function ($e) {
      // alert();

      if($('.toggle').hasClass('none')) {
        return;
      }

      $('.toggle').removeClass("arrow-down");

      if (!$(this).next().hasClass('show')) {
        // @ts-ignore
        $(this).parent().children('.collapse.show').collapse('hide');
        $(this).toggleClass('arrow-down');

      }
      // @ts-ignore
      $(this).next().collapse('toggle');

    });
  }

}
