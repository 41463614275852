
<!-- Fullscreen Slider Start -->
<div class="slider bg-navy-blue home">
  <section class="slider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="slide-inner bg-image" data-background="assets/images/qor/130-bbl-plant-intro.jpg"
               data-text="QOR<span>Industrial Services</span>">
            <div class="container">
              <h6 data-swiper-parallax="100">We Are QOR</h6>
              <h2 data-swiper-parallax="200">Industrial Services</h2>
              <p data-swiper-parallax="300">
                Our state-of-the-art automated 3D equipment combined with our experienced and skilled personnel
                ensures that we deliver the highest level of customer satisfaction. Services include cleaning,
                 hydro blasting, and end-to-end waste management services. Click below to learn more.
              </p>
              <div class="clearfix"></div>
              <a href="#" class="btn-theme bg-orange" data-swiper-parallax="200" [routerLink]="['../industrial-services']">Learn More</a>
            </div>
            <!-- end container -->
          </div>
          <!-- end slide-inner -->
        </div>
        <!-- end swiper-slide -->
        <div class="swiper-slide">
          <div class="slide-inner bg-image" data-background="assets/images/qor/vac-box-inventory-intro.jpg"
               data-text="QOR<span>Equipment and Rentals</span>">
            <div class="container">
              <h6 data-swiper-parallax="100">We Are QOR</h6>
              <h2 data-swiper-parallax="200">Equipment and Rentals</h2>
              <p data-swiper-parallax="300">QOR Equipment and Rentals is the solution to all your waste rental needs.
                Our rental fleet, which is comprised of company-owned boxes and tanks, containment berms, specialty containers and tankers,
                and an assortment of hoses and fittings is diverse enough to handle waste projects in various environments of all sizes.</p>
              <div class="clearfix"></div>
              <a href="#" class="btn-theme bg-orange" data-swiper-parallax="200" [routerLink]="['../equipment-and-rentals']">Learn More</a>
            </div>
            <!-- end container -->
          </div>
          <!-- end slide-inner -->
        </div>
        <!-- end swiper-slide -->
        <div class="swiper-slide">
          <div class="slide-inner bg-image" data-background="assets/images/qor/box-in-plant-intro.jpg"
               data-text="QOR<span>Clean Out (Coming Soon)</span>">
            <div class="container">
              <h6 data-swiper-parallax="100">We Are QOR</h6>
              <h2 data-swiper-parallax="200">Clean Out (Coming Soon)</h2>
              <p data-swiper-parallax="300">Be on the look out for our tank cleaning and wash-out services coming soon!</p>
              <div class="clearfix"></div>
<!--              <a href="#" class="btn-theme bg-orange" data-swiper-parallax="200">Learn More</a>-->
            </div>
            <!-- end container -->
          </div>
          <!-- end slide-inner -->
        </div>
        <!-- end swiper-slide -->
      </div>
      <!-- end swiper-wrapper -->
      <div class="swiper-custom-pagination"></div>
      <!-- end swiper-custom-pagination -->
    </div>
    <!-- end swiper-container -->
  </section>
  <!-- end slider -->
</div>
<!-- Fullscreen Slider End -->

<app-about-us [compressed]="true"></app-about-us>

<!-- Callout Start -->
<section class="wide-tb-80 bg-navy-blue callout-style-1 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-12 mb-0">
        <h4 class="h4-xl">Interested in working with QOR Companies?</h4>
      </div>
      <div class="col">
<!--        <div class="center-text">-->
<!--          We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized-->
<!--          approach-->
<!--        </div>-->
      </div>
      <div class="col-sm-auto">
        <a href="#" class="btn-theme bg-white light" [routerLink]="['../contact-us']">Get In Touch
          <i class="icofont-rounded-right"></i></a>
      </div>
    </div>
  </div>
</section>
<!-- Callout End -->

<!-- Theme Custom FIle -->
<!--<script src="assets/js/site-custom.js"></script>-->
